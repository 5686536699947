import { useState, useEffect } from 'react';
import { 
    Container,
    Row, 
    Col, 
  } from 'react-bootstrap';
import {motion} from 'framer-motion';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import WPService from '../services/WPService';
import Spinner from '../components/Spinner';




function IlProgetto() {
    
    const [showSpinner, setShowSpinner] = useState(true);
    const [page, setPage] = useState([]);
    const pageSlug = 'il-progetto';


    useEffect(() => {
        getPage();
    },[]);


    async function getPage () {
        const pageRetrieved = await WPService.getPageBySlug(pageSlug);
        setPage(pageRetrieved.data)
        setShowSpinner(false);
    }


    return (
        <motion.div
            animate={{
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
            <Hero 
                title="ATTIVITA'"
                subTitle="É difficile rappresentare una Regione con una semplice app, ma il mio intento é quello di provarci." 
            />
            <Container>
                <Spinner show={showSpinner}/>
                <Row className='row align-items-center'>
                    {page && page.length ?
                        page.map((pageSingle, index) => {
                            return <Col className='col-lg-12 ms-auto' key={index}
                                dangerouslySetInnerHTML={{ __html: pageSingle.content.rendered }}>
                                </Col>
                        }) : null}
                </Row>
            </Container>
            <Footer siteTitle = {process.env.REACT_APP_NAME} />
        </motion.div>
    )
}

export default IlProgetto;