import '../assets/css/custom.css';
import {useParams} from "react-router-dom";
import { useState, useEffect } from 'react';
import {Container,Row, Col, Modal, Button} from 'react-bootstrap';
import Menu from '../components/Menu';
import CardEvento from '../components/CardEvento';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
import Spinner from '../components/Spinner';
import WCService from '../services/WCService';
import {WCProduct} from '../models/WCProduct';
import {WCBaseCategory} from '../models/WCBaseCategory';



export default function CategoriaAttivita(){
    const [show, setShow] = useState(false);
    const [eventi, setEventi] = useState();
    const [urlHeroImage, setUrlHeroImage] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const params = useParams();
    const [tipoAttivita, setTipoAttivita] = useState('');
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(()=>{
        setTipoAttivita(params.attivita);
        getEventi(params.attivita);
    }, [window.location.pathname]);
    

    async function getEventi(tipoAttivita){
        const categorySlug = await WCService.getProductsCategoryIdByCategorySlug(tipoAttivita);
        const products = await WCService.getProductsByCategoryID(categorySlug.data[0].id);
        const productsMapped = products.data.map(product => new WCProduct(product));
        const categoryMapped = new WCBaseCategory(categorySlug.data);

        setEventi(productsMapped);
        setUrlHeroImage(categoryMapped);
        setShowSpinner(false);
    }
    

    return (
        <motion.div
            animate={{
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
            <div className="page-header min-vh-75" style={{backgroundImage: `url(${urlHeroImage.src})`}}>
                <Container>
                    <Spinner show={showSpinner}/>
                    <Row className="pt-2 pb-5">
                        <Col><Menu /></Col>
                    </Row>
                    <Row className="pt-1 mt-5">
                        <Col>
                            <div className="col-lg-5 text-center mx-auto heroStyle">
                                <h1 className="pt-3 mt-n5 titleCapitalized">{tipoAttivita}</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />

            <Container>
                <Row>
                    {eventi && eventi.length ?
                        eventi.map((evento, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                            <CardEvento {...evento} />
                        </div>    
                    )) : null}
                </Row>
            </Container>

            <Footer siteTitle = {process.env.REACT_APP_NAME} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </motion.div>
    );
}
