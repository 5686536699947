import {motion} from 'framer-motion';


export default function NotFound(){
    return (
        <motion.div
            animate={{
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
            <h1>NON abbiamo trovato la rotta</h1>
        </motion.div>
    )
}