import axios from "axios";


const wpAuthCredentials =  {
    username: `${process.env.REACT_APP_CK}`,
    password: `${process.env.REACT_APP_CS}`
};

const basePath = `${process.env.REACT_APP_BASEPATH}`;


const WCService = {
    getProducts: async function() {
        return await axios
        .get(`${basePath}/wp-json/wc/v3/products`, {
            auth: wpAuthCredentials
        });
    },

    getProduct: function(id) {
        axios
        .get(`${basePath}/wp-json/wc/v3/products/${id}`, {
            auth: wpAuthCredentials
        })
        .then(data => {
            let res = data.data;
            console.log(res);
            return res;
        });
    },

    getProductBySlug: async function(slug) {
        return await axios
        .get(`${basePath}/wp-json/wc/v3/products/?slug=${slug}`, {
            auth: wpAuthCredentials
        });
    },

    getProductsCategories: async function() {
        return await axios
        .get(`${basePath}/wp-json/wc/v3/products/categories`, {
            auth: wpAuthCredentials
        });
    },

    getProductsCategoryIdByCategorySlug: async function(categorySlug) {
        return await axios
        .get(`${basePath}/wp-json/wc/v3/products/categories?slug=${categorySlug}`, {
            auth: wpAuthCredentials
        });
    },

    getProductsByCategoryID: async function(categoryId) {
        return await axios
        .get(`${basePath}/wp-json/wc/v3/products?category=${categoryId}`, {
            auth: wpAuthCredentials
        });
    },

};

export default WCService;