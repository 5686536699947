// import { Card } from "react-bootstrap";
import { 
    Card, 
    Modal, 
    Button,
    Spinner, 
  } from 'react-bootstrap';
import {
    Link 
} from "react-router-dom";


function CardCustom(props){
    const image = props.image;
    // console.log(props);

    return (
        <>
            {/* <p>{props.description}</p> */}
            {/* <Card.Img variant="top" src ={image} /> */}
            <Card>
                <Card.Img variant="top" src={props.image.src} />
                <Card.Body>
                    <Card.Title>{props.name}</Card.Title>
                    <Card.Text>{props.description}</Card.Text>
                    <Link to={`/attivita/${props.slug}`}>
                        <Button variant="primary">Gli eventi</Button>
                    </Link>
                </Card.Body>
            </Card>
        </>
    );

}



export default CardCustom;