import {Container,Row, Col, Button, Card, ListGroup} from 'react-bootstrap';
import {React, useState, useEffect } from 'react';
import {useParams, useLocation, useNavigate} from "react-router-dom";
import WCService from '../services/WCService';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
import Spinner from '../components/Spinner';
import {WCProduct} from '../models/WCProduct';




function DettaglioEvento(props){
    const image = props.image;
    const [urlHeroImage, setUrlHeroImage] = useState('');
    const [tipoAttivita, setTipoAttivita] = useState('');
    const [showSpinner, setShowSpinner] = useState(true);
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [dettaglioEvento, setDettaglioEvento] = useState([]);


    useEffect(()=>{
        getProduct();
    }, []);

    async function getProduct(){
        const product = await WCService.getProductBySlug(params.singola);
        const productMapped = product.data.map(product => new WCProduct(product));
        setShowSpinner(false);
        setDettaglioEvento(productMapped);
    }


    return (
        <motion.div
            animate={{
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
            {dettaglioEvento && dettaglioEvento.length ?
                dettaglioEvento.map((evento, index) => (
                    <div key={index}>
                        <div className="page-header min-vh-75" style={{backgroundImage: `url(${evento.images[0].src})`}}>
                            <Container>
                                <Spinner show={showSpinner}/>
                                <Row className="pt-2 pb-5">
                                    <Col><Menu /></Col>
                                </Row>
                                <Row className="pt-1 mt-5">
                                    <Col>
                                        <div className="col-lg-5 text-center mx-auto heroStyle">
                                            <h1 className="pt-3 mt-n5 titleCapitalized">{evento.name}</h1>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />

                        <Container>
                            <Row>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{evento.name}</Card.Title>
                                        <Card.Text dangerouslySetInnerHTML={{ __html: evento.description }}></Card.Text>
                    
                                        <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <strong>Telefono:</strong> <a href={`tel:${evento.telefono}`}>{evento.telefono}</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <strong>Email:</strong> <a href={`mailto:${evento.email}`}>{evento.email}</a>
                                        </ListGroup.Item>
                                            <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                                        </ListGroup>

                                        <div className="row float-end">
                                            <div className='col'>
                                                <Button variant="primary" onClick={() => {
                                                    navigate(-1);
                                                }}>Torna alle attività</Button>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Row>
                        </Container>

                        <Footer siteTitle = {process.env.REACT_APP_NAME} />
                    </div>
            )) : null}
        </motion.div>
    );
}

export default DettaglioEvento;