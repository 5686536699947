import './App.css';
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from './pages/AnimatedRoutes';

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default App;
