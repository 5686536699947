export class WCBaseProduct {
    constructor(products) {
      this.id = products.id;
      this.name = products.name;
      this.slug = products.slug;
      this.type = products.type;
      this.status = products.status;
      this.description = products.description;
      this.short_description = products.short_description;
      this.sku = products.sku;
      this.price = products.price;
      this.categories = products.categories;
      this.tag = products.tag;
      this.images = products.images;
    }
}