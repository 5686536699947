import axios from "axios";


const wpAuthCredentials =  {
    username: `${process.env.REACT_APP_CK}`,
    password: `${process.env.REACT_APP_CS}`
};

const basePath = `${process.env.REACT_APP_BASEPATH}`;


const WPService = {
    getPosts: async function() {
        return await axios
        .get(`${basePath}/wp-json/wp/v2/posts/`, {
            auth: wpAuthCredentials
        })
    },

    getPostsById: async function(id) {
        return await axios
        .get(`${basePath}/wp-json/wp/v2/posts/${id}`, {
            auth: wpAuthCredentials
        })
    },

    getPostsBySlug: async function(slug) {
        return await axios
        .get(`${basePath}/wp-json/wp/v2/posts?slug=${slug}`, {
            auth: wpAuthCredentials
        })
    },

    getPageBySlug: async function(slug) {
        return await axios
        .get(`${basePath}/wp-json/wp/v2/pages?slug=${slug}`, {
            auth: wpAuthCredentials
        });
    },

    // getProductsCategories: async function() {
    //     return await axios
    //     .get(`${basePath}/wp-json/wc/v3/products/categories`, {
    //         auth: wpAuthCredentials
    //     });
    //     // .then(data => {
    //     //     let res = data.data;
    //     //     console.log(res);
    //     //     return res;
    //     // });
    // },

    // getProductsCategoryIdByCategorySlug: async function(categorySlug) {
    //     return await axios
    //     .get(`${basePath}/wp-json/wc/v3/products/categories?slug=${categorySlug}`, {
    //         auth: wpAuthCredentials
    //     });
    // },

    // getProductsByCategoryID: async function(categoryId) {
    //     return await axios
    //     .get(`${basePath}/wp-json/wc/v3/products?category=${categoryId}`, {
    //         auth: wpAuthCredentials
    //     });
    // },
};

export default WPService;