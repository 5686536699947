import React from "react";
import {
    Routes,
    Route,
  } from "react-router-dom";
import Home from '../pages/Home';
import IlProgetto from "../pages/IlProgetto";
import CategorieAttivita from './CategorieAttivita';
import CategoriaAttivita from './CategoriaAttivita';
import NotFound from '../pages/NotFound';
import DettaglioEvento from "./DettaglioEvento";


export default function AnimatedRoutes(){
    return (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/il-progetto" element={<IlProgetto />} />
                <Route path="/attivita" element={<CategorieAttivita />} />
                <Route path="/attivita/:attivita" element={<CategoriaAttivita />}/>
                <Route path="/attivita/:attivita/:singola" element={<DettaglioEvento />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
    )
}